$(document).ready(function() {
    $(document).on('keyup', '.firstletter_capital', function() {
        $(this).val(function(i, v) {
            return v.replace(/[a-zA-z]/, function(c) {
                return c.toUpperCase();
            })
        })
    });
    $(document).on('keypress', '.txt_only', function(event) {
        var regex = new RegExp("^[a-zA-Z ]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });
    // $(window).on('popstate', function(event) {
    // });
    // $('.txt_only').keypress(function(e) {
    //     var regex = new RegExp("^[a-zA-Z0-9]+$");
    //     var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    //     if (regex.test(str)) {
    //         return true;
    //     }
    //     e.preventDefault();
    //     return false;
    // });
    // $('.txt_only').bind(function(e) {
    //     if (e.shiftKey || e.ctrlKey || e.altKey) {
    //         e.preventDefault();
    //     } else {
    //         var key = e.keyCode;
    //         if (!((key == 8) || (key == 32) || (key == 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
    //             e.preventDefault();
    //         }
    //     }
    // });

    $('video').bind('play', function() {
        activated = this;
        $('video').each(function() {
            if (this != activated) this.pause();
        });
    });
    // $('#gum_border_0').addClass('gum_border_grey');
    $("body").on('click', '.gum_hover', function(e) {
        $('.gum_hover').removeClass('gum_border_grey');
        if (!$(this).hasClass('gum_border_grey')) {
            $(this).toggleClass('gum_border_grey');
        }
    });

    $("body").on('click', '.teeth_hover', function(e) {
        $('.teeth_hover').removeClass('teeth_border_grey');
        if (!$(this).hasClass('teeth_border_grey')) {
            $(this).toggleClass('teeth_border_grey');
        }
    });

    // $("body").on('click', '.shade_hover', function(e) {
    //     $('.shade_hover').removeClass('shade_border_grey');
    //     if (!$(this).hasClass('shade_border_grey')) {
    //         $(this).toggleClass('shade_border_grey');
    //     }
    // });

    $("body").on('click', '.oral_hygiene_btn', function(e) {
        $(this).addClass('active').siblings().removeClass('active');
    });

    $("body").on('click', '.half_opacity', function(e) {
        $(this).addClass('full_opacity').siblings().removeClass('full_opacity');
    });

    // changing the colors based on oral score 
    function total_oralscore() {
        var value = Number($("#total_oralscore").text());
        switch (value !== '') {
            case value >= 8 && value <= 10:
                $("#oralscore_graphic").attr("src", "http://localhost/oraltech/assets/images/oralscore_graphic_green.png");
                $('#color_ranking').text("Good").addClass('green_color');
                break;
            case value >= 5 && value <= 7:
                $("#oralscore_graphic").attr("src", "http://localhost/oraltech/assets/images/oralscore_graphic_orange.png");
                $('#color_ranking').text("Moderate").addClass('orange_color');
                break;
            case value <= 4:
                $("#oralscore_graphic").attr("src", "http://localhost/oraltech/assets/images/oralscore_graphic_red.png");
                $('#color_ranking').text("Danger").addClass('red_color');
                break;
            default:
                $("#oralscore_graphic").attr("src", "http://localhost/oraltech/assets/images/oralscore_graphic_red.png");
                $('#color_ranking').text("Danger").addClass('red_color');
                break;
        }
    }
    total_oralscore();
    //end

    //Changing image brightness 
    $('body').on('click', '.select_preference', function() {
        // $(this).addClass('image_effect').siblings().removeClass('image_effect');
        if ($('input[name=test]:checked').val() == "oral_health_score") {
            $(this).siblings().find('.smile_design').removeClass('image_effect');
            $(this).find('.oral_score_img').addClass('image_effect');

        } 
        if ($('input[name=test]:checked').val() == "smile_design") {
            $(this).siblings().find('.oral_score_img').removeClass('image_effect');
            $(this).find('.smile_design').addClass('image_effect');

        }
    });

    // sliderInit();
    $("body").on("click", "#btn_modal", function() {

        $("#myModal_1").modal("show");

        //appending modal background inside the red section
        $('.modal-backdrop').appendTo('.red');

        //remove the padding right and modal-open class from the body tag which bootstrap adds when a modal is shown
        $('body').removeClass("modal-open")
        $('body').css("padding-right", "");
    });
});

//Slider
// function sliderInit() {
//     $('.teeth_div').slick({
//         arrows: true,
//         prevArrow: "<button type='button' class='slick-prev pull-left'><i class='icon-arrow_left slick_icons' aria-hidden='true'></i></button>",
//         nextArrow: "<button type='button' class='slick-next pull-right'><i class='icon-arrow_right slick_icons' aria-hidden='true'></i></button>",
//         infinite: true,
//         slidesToShow: 2,
//         slidesToScroll: 2,
//     });
// }

$("body").on('click', '#instructions-tab', function(e) {
    for (var i = 0; i <= 4; i++) {
        players[i].pauseVideo();
    }
});
$('#myModalPrev').on('hidden.bs.modal', function() {
    $('#myModalPrev .modal-body').empty();
});

var players;
players = new Array()
onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {

    console.log("yes")
    players[0] = new YT.Player('player0', {
        height: '250',
        width: '640',
        enablejsapi: '1',
        videoId: 'VCH8zdrlfyU',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    players[1] = new YT.Player('player1', {
        height: '250',
        width: '640',
        enablejsapi: '1',
        videoId: 'bIKvTz4ix38',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    players[2] = new YT.Player('player2', {
        height: '250',
        width: '640',
        enablejsapi: '1',
        videoId: 'nBx7CQCO7LM',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    players[3] = new YT.Player('player3', {
        height: '250',
        width: '640',
        enablejsapi: '1',
        videoId: 'OGTQLTImHk0',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    players[4] = new YT.Player('player4', {
        height: '250',
        width: '640',
        enablejsapi: '1',
        videoId: 'cLEklsoh4ic',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}

function onPlayerReady(event) {
    event.target.playVideo();
}

function onPlayerStateChange(event) {

    if (event.data == YT.PlayerState.PLAYING) {
        var playerid = event.target.h.id; //(event.target.f).attr("id");
        console.log(playerid, event.target.h.id)
        for (var i = 0; i <= 4; i++) {
            console.log("player" + i.toString());
            if ("player" + i.toString() != playerid) {
                players[i].pauseVideo();
            }
        }
    }
}


function loadScript() {
    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
        console.log("Load1")
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}